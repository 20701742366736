<template>
  <div class="wordLearning">
    <div class="wordLearning-search">

      <el-select v-model="searchParams.learningId" clearable placeholder="请选择日常学习">
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select v-model="searchParams.masterySituation" clearable placeholder="请选择状态">
        <el-option
          v-for="(item,index) in wordMastery"
          :key="index"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input
        v-model="searchParams.keyWords"
        placeholder="请输入关键词搜索"
        prefix-icon="el-icon-search"
        clearable
        style="width: 300px;"
      />
    </div>
    <div v-if="total > 0" class="wordLearning-content">
      <div v-for="(i,index) in word" :key="index" class="wordLearning-content-item">
        <Word
          :title="i.word"
          :is-grasp-show="true"
          :grasp="i.masterySituation"
          :index="index"
          :is-answer-show="true"
          :answer="i.interpretation"
          :is-pron-show="true"
          :american-pron="i.americanPronunciation"
          :british-pron="i.britishPronunciation"
          @graspChange="graspChange"
        />
      </div>
      <div class="wordLearning-content-pagination">
        <el-pagination
          background
          :page-size="12"
          layout="prev, pager, next,jumper"
          :total="total"
          @current-change="currentChange"
        />
      </div>
    </div>
    <el-empty v-else style="min-height: 500px" class="none-box" description="暂无数据" :image="require('@/assets/images/empty.png')" />
    <indexBottom />
  </div>
</template>

<script>
import Word from '@/views/courseAssignments/components/Word'
import indexBottom from '@/components/indexBottom'
import { mapState } from 'vuex'
import { editLearning, getLearningList, getWordLearning } from '@/api/course/word'

export default {
  name: 'WordLearning',
  components: {
    Word,
    indexBottom
  },
  data() {
    return {
      options: [],
      searchParams: {
        keyWords: '',
        learningId: '',
        masterySituation: ''
      },
      word: [],
      total: 0,
      pageNum: 1
    }
  },
  computed: {
    ...mapState({
      wordMastery: state => state.dict.dictTree.word_mastery['arr'],
      userInfo: state => state.user.userInfo
    })
  },
  watch: {
    searchParams: {
      deep: true,
      handler() {
        this.getList()
      }
    }
  },
  created() {
    this.getStudyList()
    this.getList()
  },
  methods: {
    currentChange(page) {
      this.pageNum = page
      this.getList()
    },
    getList() {
      const params = {
        ...this.searchParams,
        stuId: this.userInfo.id,
        classId: this.userInfo.classId,
        pageSize: 12,
        pageNum: this.pageNum
      }
      getWordLearning(params).then(res => {
        this.word = res.rows
        this.total = res.total
      })
    },
    /*
    * 日常学习
    * */
    getStudyList() {
      getLearningList({ classId: this.userInfo.classId }).then(res => {
        this.options = res.rows
      })
    },
    graspChange({ command, index }) {
      this.word[index].masterySituation = command
      const item = this.word[index]
      const params = {
        wordId: item.wordId,
        stuId: this.userInfo.id,
        masterySituation: command,
        learningId: item.learningId
      }
      editLearning(params).then(res => {
        console.log({ res })
      })
    }
  }
}
</script>

<style scoped lang="less">
.wordLearning {
  &-search {
    width: 1200px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 auto 20px;
    padding: 10px 24px;
    display: flex;

    .el-select {
      margin-right: 20px;
    }
  }

  &-content {
    width: 1200px;
    min-height: 500px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 auto 100px;
    padding: 30px 24px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
    &-item {
      width: calc(100% / 4 - 15px);
      margin: 0 0 15px 15px;
    }

    &-pagination {
      position: absolute;
      right: 15px;
      bottom: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

</style>
