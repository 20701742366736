import request from "@/utils/request";

/*
* 单词学习
* */
export const getWordLearning = params => request({
  url: `api/teacher/word/list`,
  method: 'GET',
  params
})

/*
* 查询单词日常学习列表
* */
export const getLearningList = params => request({
  url: `api/word/learning/list`,
  method: 'GET',
  params
})

/*
* 修改学生单词日常学习掌握情况
* */
export const editLearning = data => request({
  url: `api/teacher/word/edit`,
  method: 'POST',
  data
})

/*
* 查询单词考试列表
* */
export const getWordTestList = params => request({
  url: `api/word/test/wordTestList`,
  method: 'GET',
  params
})

/*
* 获取单词考试详细信息
* */
export const getTestDetail = id => request({
  url: `api/word/test/${id}`,
  method: 'GET',
})

/*
* 判卷（提交试卷）
* */
export const submitTestPaper = data => request({
  url: `api/word/test/markingPaper`,
  method: 'POST',
  data
})

/*
* 老师查看单词考试的学生成绩列表：用于排行榜和查看判卷列表
* */
export const getLeaderboard = testId => request({
  url: `api/word/test/stu/score/${testId}`,
  method: 'GET',
})
