<template>
  <div class="word-card">
    <div class="word-card-header">
      <div class="word-card-header-title">
        <el-tooltip effect="light" :content="title" placement="top">
          <div style="User-select:none">{{ title }}</div>
        </el-tooltip>
      </div>
      <div v-if="isGraspShow" class="word-card-header-right">
        <el-dropdown @command="handleCommand">
          <el-button
            size="mini"
            :type="wordMastery['listClass'][grasp]"
          >
            {{ wordMastery['obj'][grasp] || '未掌握' }}
            <i class="el-icon-arrow-down el-icon--right" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="i in filterWordMastery(grasp)"
              :key="i.label"
              :command="i.value"
            >{{ i.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-if="isPronShow" class="word-card-pron">
      <span class="word-card-pron-type" @click="changePronType()">{{ pronType === 0 ? '美' : '英' }}</span>
      <span class="word-card-pron-con">{{ pronType === 0 ? americanPron : britishPron }}</span>
      <i v-if="voiceLoading" class="el-icon-loading" />
      <img v-else src="@/assets/images/shengyin_shiti.png" alt="喇叭" @click="wordPronunciation(pronType, title)">
    </div>
    <div class="word-card-body">
      <div v-if="mode === 'exam'" class="word-input">
        <slot name="input" />
      </div>
      <div v-else class="word-result">
        <div
          v-if="isAnswerShow"
          class="word-result-answer"
          :style="{marginBottom:isReplyShow?'10px':'0px'}"
        >
          正确答案：{{ answer }}
        </div>
        <div v-if="isReplyShow" class="word-result-reply" :style="replyStyle()">
          您的回答：{{ reply || '无' }}
        </div>
      </div>
    </div>
    <audio ref="audio" src=""/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { wordPronunciationApi } from '@/api/basicSkills/wordExercise'

export default {
  name: 'Word',
  props: {
    title: { type: String, required: true }, // 标题

    answer: { type: String }, // 答案
    isAnswerShow: { type: Boolean, default: false }, // 是否展示答案

    reply: { type: String }, // 回答
    isReplyShow: { type: Boolean, default: false }, // 是否展示回答

    grasp: { type: String }, // 掌握状态
    isGraspShow: { type: Boolean, default: false }, // 是否展示掌握状态

    americanPron: { type: String }, // 美式发音
    britishPron: { type: String }, // 英式发音
    isPronShow: { type: Boolean, default: false }, // 是否展示发音

    mode: {
      type: String,
      default: 'info',
      validator: v => ['info', 'exam'].includes(v)
    },
    index: { type: Number, required: true }, // 索引

    correctFlag: { type: Number }// 答案是否正确
  },
  computed: {
    ...mapState({
      wordMastery: state => state.dict.dictTree.word_mastery
    })
  },
  data() {
    return {
      pronType: 0, // 0美音 1英音
      voiceLoading: false // 0美音 1英音
    }
  },
  methods: {
    replyStyle() {
      let color = '#666666'
      if (this.isAnswerShow) {
        color = this.correctFlag === 1 ? '#37C187' : '#F12C2C'
      }
      return {
        color
      }
    },
    filterWordMastery(type) {
      return this.wordMastery['arr'].filter(i => i.value !== type)
    },
    handleCommand(command) {
      this.$emit('graspChange', { command, index: this.index })
    },
    changePronType() {
      this.pronType = this.pronType === 0 ? 1 : 0
    },
    /* 单词发音 */
    wordPronunciation(pronType, word) {
      this.voiceLoading = true
      wordPronunciationApi({
        type: pronType,
        audio: word
      }).then(res => {
        const audio = this.$refs.audio
        audio.src = window.URL.createObjectURL(res)
        audio.volume = 1
        audio.play()
      }).finally(() => {
        this.voiceLoading = false
      })
    }
  }
}
</script>

<style scoped lang="less">
.word-card {
  width: 272px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 20px;
  text-align: left;
  border: 1px solid #FFFFFF;
  height: 100%;
  /*里面的代码可以根据自己需求去进行更改*/
  /* 设置滚动条的样式 */

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* 滚动槽 */

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  /* 滚动条滑块 */

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(40, 113, 234, 0.2);
    border-color: #406EF2;
  }

  &-header {
    display: flex;
    align-items: center;

    &-title {
      flex-shrink: 0;
      flex-grow: 1;
      width: 145px;
      padding-right: 10px;

      div {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 600;
        color: #333333;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
      }
    }

    &-right {
      .el-button--info {
        background-color: #CFCFCF;
        border-color: #CFCFCF;
      }

      .el-button--primary {
        background-color: #4C97FD;
        border-color: #4C97FD;
      }

      .el-button--warning {
        background-color: #FC7F19;
        border-color: #FC7F19;
      }
    }
  }
  &-pron {
    display: flex;
    align-items: center;
    &-type {
      border: 1px solid #DC7710;
      padding: 0 5px;
      color: #dc7710;
      cursor: pointer;
      font-size: 12px;
    }
    &-con {
      font-size: 14px;
      margin: 0 5px;
    }
    img {
      cursor: pointer;
    }
  }

  &-body {
    .word-result {
      margin-top: 14px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      &-answer {
        color: #999999;
        max-height: 40px;
        overflow: hidden;
        padding-right: 4px;
        work-break: break-all;

        &:hover {
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }

    .word-input {
      margin-top: 10px;
    }
  }

}

</style>
